import React from "react";

function MainWid() {
  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="project-details-wrap">
              <div className="project-details-thumb">
                <img
                  src={require(`../../../assets/img/project/project_img02.jpg`)}
                  alt=""
                />
              </div>
              <div className="project-details-content">
                <h2 className="title">
                  Siding Installation in Hamden
                </h2>
                <p className="info-one">
                Our recent siding installation project in Hamden was a testament to our team's expertise and commitment to customer satisfaction. The homeowner was seeking to modernize their home's exterior and improve its energy efficiency. We carefully assessed the existing siding and recommended a durable vinyl siding that would complement the home's architectural style and provide superior protection against the elements. Our skilled technicians completed the installation with precision and attention to detail, ensuring a seamless and aesthetically pleasing finish. 
                </p>
                <p className="info-two">
                The homeowner was thrilled with the results, noting the significant improvement
                  <span> in their home's curb appeal and energy efficiency.</span> This project is just one example of our 
                  <span> dedication to providing high-quality siding installation</span> services to our customers.
                </p>
                <div className="project-challenge-wrap">
                  <div className="row">
                    <div className="col-xl-5">
                      <div className="challenge-content">
                        <h2 className="title">The Challenge of Project</h2>
                        <p className="info-three">
                        One of the main challenges we encountered during this project was the presence of existing siding that was in poor condition and difficult to remove. The old siding was warped, damaged, and adhered to the underlying structure with a stubborn adhesive. We had to carefully remove the old siding without causing any damage to the home's structure or insulation. 
                        </p>
                        <p className="info-four">
                        Our team employed specialized tools and techniques to safely and efficiently remove the old siding, paving the way for a successful installation of the new siding.
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-7">
                      <div className="challenge-img">
                        <img
                          src={require(`../../../assets/img/project/project_details02.jpg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/img/project/project_details03.jpg`)}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="title-two">Project requirement</h2>
                <p className="info-five">
                The homeowner's primary requirement was to improve the appearance of their home and increase its energy efficiency. They were looking for a durable, low-maintenance siding material that would complement the home's architectural style. Additionally, the homeowner wanted the project to be completed within a specific timeframe to minimize disruption to their daily life.
                </p>
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>Aesthetics
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Energy efficiency
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Durability
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Timeline
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="project-sidebar">
              <div className="project-widget">
                <h4 className="widget-title">Project Details</h4>
                <div className="project-info-list">
                  <ul className="list-wrap">
                    <li>
                      <span>Start Date :</span> 10 Jan 2024
                    </li>
                    <li>
                      <span>End Date :</span> 29 Jan 2024
                    </li>
                    <li>
                      <span>Clients :</span> John McAllister
                    </li>
                    <li>
                      <span>Tags:</span> Siding Installation
                    </li>
                    <li>
                      <span>Category :</span> Siding
                    </li>
                  </ul>
                </div>
              </div>
              <div className="project-widget">
                <h4 className="widget-title">Need You Help?</h4>
                <div className="project-contact">
                  <ul className="list-wrap">
                    <li><a href="tel:+12035100920">
                      <i className="fas fa-phone-alt"></i>+12035100920</a>
                    </li>
                    <li><a href="mailto:lcgeneralconstructionllc@gmail.com">
                      <i className="fas fa-envelope"></i>lcgeneralconstructionllc@gmail.com</a>
                    </li>
                    <li><a href="https://maps.app.goo.gl/iqDU54xQqGo6fbJt5">
                      <i className="fas fa-map-marker-alt"></i>694 Baldwin St, Waterbury <br /> CT 06706, Estados Unidos</a>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
