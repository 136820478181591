import React from "react";

function MainWid() {
  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="project-details-wrap">
              <div className="project-details-thumb">
                <img
                  src={require(`../../../assets/img/project/project_img03.jpg`)}
                  alt=""
                />
              </div>
              <div className="project-details-content">
                <h2 className="title">
                  Gutter Installation in Wallingford
                </h2>
                <p className="info-one">
                Our recent gutter installation project in Wallingford was a testament to our team's expertise and commitment to customer satisfaction. The homeowner was experiencing water damage and was concerned about the appearance of their home. We conducted a thorough inspection and determined that a new gutter system was necessary to protect their property. Our skilled technicians installed a seamless gutter system with downspouts that effectively directs rainwater away from the foundation, preventing erosion and potential water damage.
                </p>
                <p className="info-two">
                The homeowner was delighted with 
                  <span> the results and praised our professionalism</span> and attention to detail. This project is 
                  <span> just one example of our dedication</span> to providing high-quality gutter installation services to our customers.
                </p>
                <div className="project-challenge-wrap">
                  <div className="row">
                    <div className="col-xl-5">
                      <div className="challenge-content">
                        <h2 className="title">The Challenge of Project</h2>
                        <p className="info-three">
                        One of the main challenges we encountered during this project was the complex roofline of the home. The existing gutters were uneven and poorly maintained, which made it difficult to install the new system seamlessly. We had to carefully measure and cut the gutters to ensure a precise fit and prevent leaks. 
                        </p>
                        <p className="info-four">
                        Additionally, the homeowner's property had several trees that cast shade on the roof, which could potentially affect the flow of rainwater. We took this into consideration when designing the gutter system to ensure adequate drainage.
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-7">
                      <div className="challenge-img">
                        <img
                          src={require(`../../../assets/img/project/project_details04.jpg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/img/project/project_details05.jpg`)}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="title-two">Project requirement</h2>
                <p className="info-five">
                The homeowner's primary requirement was to protect their home from water damage and improve its overall appearance. They were concerned about the potential for leaks, mold, and erosion caused by improper water drainage. Additionally, the homeowner wanted a gutter system that was both functional and aesthetically pleasing, complementing the style of their home.
                </p>
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>Water damage prevention
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Aesthetic appeal
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Functionality
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Durability
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="project-sidebar">
              <div className="project-widget">
                <h4 className="widget-title">Project Details</h4>
                <div className="project-info-list">
                  <ul className="list-wrap">
                    <li>
                      <span>Start Date :</span> 16 Oct 2023
                    </li>
                    <li>
                      <span>End Date :</span> 21 Oct 2023
                    </li>
                    <li>
                      <span>Clients :</span> Robert Lee.
                    </li>
                    <li>
                      <span>Tags:</span> Gutter Installation
                    </li>
                    <li>
                      <span>Category :</span> Gutter
                    </li>
                  </ul>
                </div>
              </div>
              <div className="project-widget">
                <h4 className="widget-title">Need You Help?</h4>
                <div className="project-contact">
                  <ul className="list-wrap">
                    <li><a href="tel:+12035100920">
                      <i className="fas fa-phone-alt"></i>+12035100920</a>
                    </li>
                    <li><a href="mailto:lcgeneralconstructionllc@gmail.com">
                      <i className="fas fa-envelope"></i>lcgeneralconstructionllc@gmail.com</a>
                    </li>
                    <li><a href="https://maps.app.goo.gl/iqDU54xQqGo6fbJt5">
                      <i className="fas fa-map-marker-alt"></i>694 Baldwin St, Waterbury <br /> CT 06706, Estados Unidos</a>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
