import React from "react";

function WorkArea() {
  return (
    <section className="work-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Working Plan</span>
              <h2 className="title">Project Plan Working Process</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img01.png`)}
                  alt=""
                />
                <h4 className="number">01</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Inspection</h2>
                <p>
                Our skilled team will conduct a thorough inspection of your project to assess their condition and recommend necessary repairs or replacements.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img02.png`)}
                  alt=""
                />
                <h4 className="number">02</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Free Estimate</h2>
                <p>
                Get a free estimate and expert advice for your project. We'll recommend the best solution to protect your home.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img03.png`)}
                  alt=""
                />
                <h4 className="number">03</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Schedule Estimate</h2>
                <p>
                Get a free estimate for your project today! Our team will conduct a thorough inspection and provide you with a personalized quote tailored to your specific needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img04.png`)}
                  alt=""
                />
                <h4 className="number">04</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Installation or Service</h2>
                <p>
                From installation to repairs, we've got you covered. Our team provides top-quality workmanship and reliable results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkArea;
