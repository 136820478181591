import React from "react";
import HeaderFour from "../Layouts/Headers/HeaderFour";
import FooterOne from "../Layouts/Footers/FooterOne";
import Hero from "./Hero";
import FeatureArea from "./FeatureArea";
import AboutArea from "../HomeTwo/AboutArea";
import ServiceArea from "./ServiceArea";
import ProjectArea from "./ProjectArea";
import MultipleArea from "../HomeTwo/MultipleArea";
import SupportArea from "../HomeTwo/SupportArea";
import TestimonialArea from "../About/TestimonialArea";
import Blogs from "../HomeOne/Blogs";
import NewsLetterArea from "../HomeOne/NewsLetterArea";
import HeaderThree from "../Layouts/Headers/HeaderThree";

function HomeFour() {
  return (
    <>
      <div className="header-top-wrap">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-9">
              <div className="header-top-left">
                <ul className="list-wrap">
                  <li>Welcome to Roofx Construction</li>
                  <li>
                    <i className="fas fa-phone-alt"></i>
                    <a href="tel:0123456789">+88 ( 5548 ) 6548</a>
                  </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                    <a href="mailto:infoyour@gmail.com">infoyour@gmail.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-3">
              <div className="header-top-right">
                {/* <div className="header-lang">
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={require(`../../assets/img/icon/united-states.jpg`)}
                        alt=""
                      />{" "}
                      English
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <a className="dropdown-item" href="/">
                        <img
                          src={require(`../../assets/img/icon/russia.jpg`)}
                          alt=""
                        />
                        Russia
                      </a>
                      <a className="dropdown-item" href="/">
                        <img
                          src={require(`../../assets/img/icon/india.jpg`)}
                          alt=""
                        />
                        India
                      </a>
                      <a className="dropdown-item" href="/">
                        <img
                          src={require(`../../assets/img/icon/bangladesh.jpg`)}
                          alt=""
                        />
                        Bangla
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="header-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeaderThree />
      <main>
        <Hero />
        <FeatureArea />
        <AboutArea />
        <ServiceArea />
        <ProjectArea />
        <MultipleArea />
        {/* <SupportArea /> */}
        <TestimonialArea className="testimonial-area-three  pt-115" />
        {/* <Blogs /> */}
        {/* <NewsLetterArea /> */}
      </main>
      <FooterOne />
    </>
  );
}

export default HomeFour;
