import React from "react";
import BlogSidebar from "./BlogSidebar";

function MainWid() {
  return (
    <section className="inner-blog-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="blog-post-item">
                  <div className="blog-post-thumb">
                    <a href="/blogs/blog">
                      <img
                        src={require(`../../assets/img/blog/blog_img01.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <a href="/blogs" className="tag">
                      Quality Materials
                    </a>
                    <div className="blog-meta">
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-user"></i> By{" "}
                          <a href="/blogs/blog">Admin</a>
                        </li>
                        <li>
                          <i className="fas fa-calendar-alt"></i>23 Dec 2022
                        </li>
                      </ul>
                    </div>
                    <h2 className="title">
                      <a href="/blogs/blog">
                        Building worker help each other with at construction
                        site
                      </a>
                    </h2>
                    <a href="/blogs/blog" className="link-btn">
                      Read More<i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="blog-post-item">
                  <div className="blog-post-thumb">
                    <a href="/blogs/blog">
                      <img
                        src={require(`../../assets/img/blog/blog_img02.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <a href="/blogs" className="tag">
                      House Roof Work
                    </a>
                    <div className="blog-meta">
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-user"></i> By{" "}
                          <a href="/blogs/blog">Admin</a>
                        </li>
                        <li>
                          <i className="fas fa-calendar-alt"></i>23 Dec 2022
                        </li>
                      </ul>
                    </div>
                    <h2 className="title">
                      <a href="/blogs/blog">
                        Full shot roofers working together with helmets
                      </a>
                    </h2>
                    <a href="/blogs/blog" className="link-btn">
                      Read More<i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="blog-post-item">
                  <div className="blog-post-thumb">
                    <a href="/blogs/blog">
                      <img
                        src={require(`../../assets/img/blog/blog_img03.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <a href="/blogs" className="tag">
                      Construction Engineer
                    </a>
                    <div className="blog-meta">
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-user"></i> By{" "}
                          <a href="/blogs/blog">Admin</a>
                        </li>
                        <li>
                          <i className="fas fa-calendar-alt"></i>23 Dec 2022
                        </li>
                      </ul>
                    </div>
                    <h2 className="title">
                      <a href="/blogs/blog">
                        Hard hatswork at the heavy manufacturing factory
                        industrial
                      </a>
                    </h2>
                    <a href="/blogs/blog" className="link-btn">
                      Read More<i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="blog-post-item">
                  <div className="blog-post-thumb">
                    <a href="/blogs/blog">
                      <img
                        src={require(`../../assets/img/blog/blog_img04.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <a href="/blogs" className="tag">
                      Quality Materials
                    </a>
                    <div className="blog-meta">
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-user"></i> By{" "}
                          <a href="/blogs/blog">Admin</a>
                        </li>
                        <li>
                          <i className="fas fa-calendar-alt"></i>23 Dec 2022
                        </li>
                      </ul>
                    </div>
                    <h2 className="title">
                      <a href="/blogs/blog">
                        Building worker help each other with at construction
                        site
                      </a>
                    </h2>
                    <a href="/blogs/blog" className="link-btn">
                      Read More<i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="blog-post-item">
                  <div className="blog-post-thumb">
                    <a href="/blogs/blog">
                      <img
                        src={require(`../../assets/img/blog/blog_img05.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <a href="/blogs" className="tag">
                      House Roof Work
                    </a>
                    <div className="blog-meta">
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-user"></i> By{" "}
                          <a href="/blogs/blog">Admin</a>
                        </li>
                        <li>
                          <i className="fas fa-calendar-alt"></i>23 Dec 2022
                        </li>
                      </ul>
                    </div>
                    <h2 className="title">
                      <a href="/blogs/blog">
                        Full shot roofers working together with helmets
                      </a>
                    </h2>
                    <a href="/blogs/blog" className="link-btn">
                      Read More<i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="blog-post-item">
                  <div className="blog-post-thumb">
                    <a href="/blogs/blog">
                      <img
                        src={require(`../../assets/img/blog/blog_img06.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <a href="/blogs" className="tag">
                      Construction Engineer
                    </a>
                    <div className="blog-meta">
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-user"></i> By{" "}
                          <a href="/blogs/blog">Admin</a>
                        </li>
                        <li>
                          <i className="fas fa-calendar-alt"></i>23 Dec 2022
                        </li>
                      </ul>
                    </div>
                    <h2 className="title">
                      <a href="/blogs/blog">
                        Hard hatswork at the heavy industry manufacturing
                        factory industrial
                      </a>
                    </h2>
                    <a href="/blogs/blog" className="link-btn">
                      Read More<i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="blog-post-item">
                  <div className="blog-post-thumb">
                    <a href="/blogs/blog">
                      <img
                        src={require(`../../assets/img/blog/blog_img07.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <a href="/blogs" className="tag">
                      House Roof Work
                    </a>
                    <div className="blog-meta">
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-user"></i> By{" "}
                          <a href="/blogs/blog">Admin</a>
                        </li>
                        <li>
                          <i className="fas fa-calendar-alt"></i>23 Dec 2022
                        </li>
                      </ul>
                    </div>
                    <h2 className="title">
                      <a href="/blogs/blog">
                        Hard hatswork at the heavy manufacturing factory
                        industrial
                      </a>
                    </h2>
                    <a href="/blogs/blog" className="link-btn">
                      Read More<i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="blog-post-item">
                  <div className="blog-post-thumb">
                    <a href="/blogs/blog">
                      <img
                        src={require(`../../assets/img/blog/blog_img08.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <a href="/blogs" className="tag">
                      Construction Engineer
                    </a>
                    <div className="blog-meta">
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-user"></i> By{" "}
                          <a href="/blogs/blog">Admin</a>
                        </li>
                        <li>
                          <i className="fas fa-calendar-alt"></i>23 Dec 2022
                        </li>
                      </ul>
                    </div>
                    <h2 className="title">
                      <a href="/blogs/blog">
                        Full shot roofers working together with helmets
                      </a>
                    </h2>
                    <a href="/blogs/blog" className="link-btn">
                      Read More<i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-wrap mt-30">
              <nav aria-label="Page navigation example">
                <ul className="pagination list-wrap">
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item next-page">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-10">
            <BlogSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
