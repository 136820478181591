import React from "react";

function MainWid() {
  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="project-details-wrap">
              <div className="project-details-thumb">
                <img
                  src={require(`../../../assets/img/project/project_img01.jpg`)}
                  alt=""
                />
              </div>
              <div className="project-details-content">
                <h2 className="title">
                  Roof replacement in Hartford
                </h2>
                <p className="info-one">
                Recently, we completed a successful roof replacement project for a homeowner in Hartford. The old roof was showing signs of wear and tear, and the homeowner was concerned about potential leaks and damage. Our team of experienced roofers conducted a thorough inspection and determined that a complete replacement was necessary.

                We removed the old roofing materials and installed a new high-quality asphalt shingle roof. The new roof is not only more durable and weather-resistant, but it also significantly improves the home's curb appeal. The homeowner is extremely satisfied with the results and has already recommended our services to their neighbors.
                </p>
                <p className="info-two">
                This project is just one example of our commitment to 
                  <span> providing exceptional roofing services</span> to our customers. We take pride in 
                  <span> our workmanship and attention to detail</span>, ensuring that every project is completed to the highest standards.
                </p>
                <div className="project-challenge-wrap">
                  <div className="row">
                    <div className="col-xl-5">
                      <div className="challenge-content">
                        <h2 className="title">The Challenge of Project</h2>
                        <p className="info-three">
                        One of the main challenges we encountered during this project was the steep pitch of the roof. Working on such a steep incline requires specialized equipment and safety precautions to prevent accidents. Additionally, the weather conditions in Hartford can be unpredictable, which can sometimes affect the progress of the project
                        </p>
                        <p className="info-four">
                        However, our experienced team was able to navigate these challenges successfully, ensuring a safe and efficient installation.
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-7">
                      <div className="challenge-img">
                        <img
                          src={require(`../../../assets/img/services/sp_img01.jpg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/img/services/sp_img02.jpg`)}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="title-two">Project requirement</h2>
                <p className="info-five">
                The homeowner's primary requirement was to replace the old, deteriorating roof with a durable and weather-resistant material. They also wanted to improve the energy efficiency of their home and enhance its curb appeal. Additionally, the project needed to be completed within a specific timeframe to minimize disruption to the homeowner's daily life.
                </p>
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>Roof condition
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Material selection
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Project timeline
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Curb appeal
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="project-sidebar">
              <div className="project-widget">
                <h4 className="widget-title">Project Details</h4>
                <div className="project-info-list">
                  <ul className="list-wrap">
                    <li>
                      <span>Start Date :</span> 27 March 2024
                    </li>
                    <li>
                      <span>End Date :</span> 13 Apr 2023
                    </li>
                    <li>
                      <span>Clients :</span> Angela B.
                    </li>
                    <li>
                      <span>Tags:</span> Roof Replacement
                    </li>
                    <li>
                      <span>Category :</span> Roofing
                    </li>
                  </ul>
                </div>
              </div>
              <div className="project-widget">
                <h4 className="widget-title">Need You Help?</h4>
                <div className="project-contact">
                  <ul className="list-wrap">
                    <li><a href="tel:+12035100920">
                      <i className="fas fa-phone-alt"></i>+12035100920</a>
                    </li>
                    <li><a href="mailto:lcgeneralconstructionllc@gmail.com">
                      <i className="fas fa-envelope"></i>lcgeneralconstructionllc@gmail.com</a>
                    </li>
                    <li><a href="https://maps.app.goo.gl/iqDU54xQqGo6fbJt5">
                      <i className="fas fa-map-marker-alt"></i>694 Baldwin St, Waterbury <br /> CT 06706, Estados Unidos</a>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
