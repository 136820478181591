import React, { useState } from "react";

function ServiceArea() {
  const [tab, setTab] = useState(1);

  return (
    <section className="services-area-three">
      <div
        className="services-bg jarallax"
        style={{
          backgroundImage: `url(${require("../../assets/img/banner/banner_bg01.jpg")})`,
        }}
      ></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title text-center white-title mb-60">
              <span className="sub-title">Discover Our Company</span>
              <h2 className="title">See Our Roofing Services Details</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="services-nav-wrap">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${tab === 1 ? "active" : ""}`}
                    onClick={() => setTab(1)}
                    id="building-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#building-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="building-tab-pane"
                    aria-selected="true"
                  >
                    Replacement
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${tab === 2 ? "active" : ""}`}
                    onClick={() => setTab(2)}
                    id="maintenance-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#maintenance-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="maintenance-tab-pane"
                    aria-selected="false"
                  >
                    Maintenance
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${tab === 3 ? "active" : ""}`}
                    onClick={() => setTab(3)}
                    id="engineer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#engineer-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="engineer-tab-pane"
                    aria-selected="false"
                  >
                    Repair
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane ${tab === 1 ? "active show" : ""}`}
                  id="building-tab-pane"
                  role="tabpanel"
                  aria-labelledby="building-tab"
                  tabIndex="0"
                >
                  <div className="services-item-three">
                    <div className="services-thumb-three">
                      <img
                        src={require(`../../assets/img/services/h4_services_img01.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="services-content-three">
                      <div className="section-title mb-30">
                        <h2 className="title">
                          Roof Replacement
                        </h2>
                      </div>
                      <p>
                      Our skilled team can handle any roofing project, from simple repairs to complete roof replacements. We specialize in roof conversions, slope changes, and custom solutions tailored to your specific needs.
                      </p>
                      <div className="services-list">
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Business
                            improvement
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Business
                            improvement
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Time
                            Administration
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Time
                            Administration
                          </li>
                        </ul>
                      </div>
                      <a href="#" className="btn">
                        Book Service
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane ${tab === 2 ? "active show" : ""}`}
                  id="maintenance-tab-pane"
                  role="tabpanel"
                  aria-labelledby="maintenance-tab"
                  tabIndex="0"
                >
                  <div className="services-item-three">
                    <div className="services-thumb-three">
                      <img
                        src={require(`../../assets/img/services/h4_services_img02.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="services-content-three">
                      <div className="section-title mb-30">
                        <h2 className="title">
                        General Roof Maintenance
                        </h2>
                      </div>
                      <p>
                      Our comprehensive roofing services include everything from power washing and moss removal to roof inspections and repairs. We can also adjust ventilation systems, replace skylights or fans, and provide roof certifications. With our expert team and high-quality materials, we're equipped to handle any roofing project, big or small.
                      </p>
                      <div className="services-list">
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Business
                            improvement
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Business
                            improvement
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Time
                            Administration
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Time
                            Administration
                          </li>
                        </ul>
                      </div>
                      <a href="#" className="btn">
                        Book Service
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane ${tab === 3 ? "active show" : ""}`}
                  id="engineer-tab-pane"
                  role="tabpanel"
                  aria-labelledby="engineer-tab"
                  tabIndex="0"
                >
                  <div className="services-item-three">
                    <div className="services-thumb-three">
                      <img
                        src={require(`../../assets/img/services/h4_services_img03.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="services-content-three">
                      <div className="section-title mb-30">
                        <h2 className="title">
                          Roof Repair
                        </h2>
                      </div>
                      <p>
                      Our skilled roofing team specializes in a wide range of repair services, from minor leak fixes to major storm damage restoration. We use high-quality materials and advanced techniques to ensure a durable and long-lasting repair. Whether you need a simple patch or a complete roof replacement, we're here to provide expert solutions tailored to your specific needs.
                      </p>
                      <div className="services-list">
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Business
                            improvement
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Business
                            improvement
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Time
                            Administration
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Time
                            Administration
                          </li>
                        </ul>
                      </div>
                      <a href="#" className="btn">
                        Book Service
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
