import React from "react";

function Hero() {
  return (
    <section
      className="banner-area-three banner-two"
      style={{
        backgroundImage: `url(${require("../../assets/img/banner/banner_bg01.jpg")})`,
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="banner-content-three">
              <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                Looking For Smart Roofing & Fixing Consulting.
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".4s">
              Experience exceptional roofing services. Learn why we're a leader in the industry.
              </p>
              <a
                // href="/project"
                className="btn wow fadeInUp"
                data-wow-delay=".6s"
              >
                Discover More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
