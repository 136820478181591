import React from "react";

function MainWid() {
  return (
    <section className="services-details-area pt-20 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-8 ">
            <div className="services-details-wrap">
              <div className="services-details-thumb">
                <img
                  src={require(`../../../assets/img/banner/banner_bg.jpg`)}
                  alt=""
                />
              </div>
              <div className="services-details-content">
                <h2 className="title">
                We Hope You Find What You are Looking for
                </h2>
                <p>
                Trust us for exceptional gutter services. Our dedicated team will provide a thorough inspection, expert installation, and ongoing maintenance to protect your home and enhance its value.
                </p>
                <div className="services-process-wrap">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8">
                      <div className="services-process-img">
                        <img
                          src={require(`../../../assets/img/project/project_details04.jpg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/img/project/project_details05.jpg`)}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="services-process-content">
                        <h2 className="title">Our Service Process</h2>
                        <ul className="list-wrap">
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon01.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">Inspection</h4>
                                <p>
                                Our skilled team will conduct a thorough inspection of your gutters to assess their condition and recommend necessary repairs or replacements.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon02.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">Free Estimate</h4>
                                <p>
                                Get a free estimate and expert advice for your gutter project. We'll recommend the best solution to protect your home.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon01.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">
                                  Installation or Service
                                </h4>
                                <p>
                                We'll start by thoroughly cleaning, sealing, and stabilizing your gutters to prevent clogs and ensure optimal performance. If necessary, we'll replace your gutters with our high-quality seamless systems.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <h2 className="title-two">
                  We Hope You Find What You are Looking for
                </h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majorty have suffered alteration in as some
                  form by injected humour, or randomised words which don't look
                  even slightly believable. If you are going a to use as passage
                  of Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of textss. All the Lorem
                  Ipsum generators on the Internet tend to repeat
                </p> */}
                <div className="service-benefits-wrap">
                  <div className="row">
                    <div className="col-lg-7 order-0 order-lg-2">
                      <div className="benefits-img">
                        <img
                          src={require(`../../../assets/img/project/project_details04.jpg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/img/project/project_details05.jpg`)}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="benefits-content">
                        <h2 className="title">Our Service Benefits</h2>
                        <p>
                        With years of experience and a commitment to excellence, our team provides exceptional services. You can count on us for reliable, high-quality work that exceeds your expectations. Our dedication to customer satisfaction ensures you'll be thrilled with the results.
                        </p>
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>EXPERTISE
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>COMPETITIVE PRICING
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>CUSTOMIZED SOLUTIONS
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>PEACE OF MIND
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
          <aside className="services-sidebar">
              <div className="services-widget">
                <h4 className="widget-title">Our All Service</h4>
                <div className="our-services-list">
                  <ul className="list-wrap">
                    <li>
                      <a href="/services/services-roofing">
                        Roofing Contractor
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-siding">
                        Siding Contractor<i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        Deck Builder<i className="fas fa-arrow-right"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="/services/services-gutter">
                        Gutter Installation<i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        Window Installation<i className="fas fa-arrow-right"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div
                className="services-widget widget-bg"
                data-background="assets/img/services/sw_bg.jpg"
              >
                <h4 className="widget-title">Get a free quote</h4>
                <form action="#" className="sidebar-form">
                  <div className="form-grp">
                    <input id="name" type="text" placeholder="Your Name" />
                  </div>
                  <div className="form-grp">
                    <input
                      id="email"
                      type="text"
                      placeholder="Your Email Address"
                    />
                  </div>
                  <div className="form-grp">
                    <textarea
                      id="message"
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-two">
                    Contact Us
                  </button>
                </form>
              </div>
              {/* <div className="services-widget">
                <h4 className="widget-title">Our Brochures Download</h4>
                <div className="download-wrap">
                  <a
                    href="../../../assets/img/services/services_details01.jpg"
                    download
                    target="_blank"
                  >
                    <i className="fas fa-cloud-download-alt"></i>Service
                    Details.pdf 65 KB
                  </a>
                  <a
                    href="../../../assets/img/services/services_details01.jpg"
                    download
                    target="_blank"
                  >
                    <i className="fas fa-file-pdf"></i>Roofing Models.doc 48 KB
                  </a>
                </div>
              </div> */}
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
