import React from "react";

function FeatureArea() {
  return (
    <section
      className="features-area-three features-bg-two"
      style={{
        backgroundImage: `url(${require("../../assets/img/bg/features_bg02.jpg")})`,
      }}
      data-background="g"
    >
      <div className="container">
        <div className="features-item-wrap">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div
                className="fetures-item-three wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="fetures-thumb-three">
                  <a href="#">
                    <img
                      src={require(`../../assets/img/images/quality_materials.jpg`)}
                      alt=""
                    />
                  </a>
                </div>
                <div className="fetures-content-three">
                  <div className="fetures-icon-three">
                    <img
                      src={
                        require(`../../assets/img/icon/features_icon01.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <h4 className="title">
                    <a href="#">Quality Materials</a>
                  </h4>
                  <p>
                  Don't settle for anything less than the best. Our roofing materials are sourced from trusted suppliers and rigorously inspected for quality and performance. We believe that your roof deserves nothing but the finest.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="fetures-item-three wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="fetures-thumb-three">
                  <a href="#">
                    <img
                      src={require(`../../assets/img/images/expert_engineer.jpg`)}
                      alt=""
                    />
                  </a>
                </div>
                <div className="fetures-content-three">
                  <div className="fetures-icon-three">
                    <img
                      src={
                        require(`../../assets/img/icon/features_icon02.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <h4 className="title">
                    <a href="#">Expert Engineer</a>
                  </h4>
                  <p>
                  Your roof is a critical component of your home's structure. Trust our experienced engineer to assess your roof's condition, identify potential issues, and recommend the best course of action for repairs or replacements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="fetures-item-three wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="fetures-thumb-three">
                  <a href="#">
                    <img
                      src={require(`../../assets/img/images/general_maintenance.jpg`)}
                      alt=""
                    />
                  </a>
                </div>
                <div className="fetures-content-three">
                  <div className="fetures-icon-three">
                    <img
                      src={
                        require(`../../assets/img/icon/features_icon03.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <h4 className="title">
                    <a href="#">General Maintenance</a>
                  </h4>
                  <p>
                  Don't wait for problems to arise. Our routine maintenance services can help identify and address potential issues before they become major problems, saving you time and money in the long run.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureArea;
