import React from "react";

function ServiceArea({ className }) {
  return (
    <section className={className || ""}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">What We Do</span>
              <h2 className="title tg-element-title">Our Services Areas</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                background: `url("../../assets/img/services/h4_services_img03.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon02.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="#">Roofing Contractor</a>
                </h2>
                <h2 className="number">01</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="#">Roofing Contractor</a>
                </h2>
                <p>
                Our comprehensive roofing services include everything from power washing and moss removal to roof inspections and repairs. 
                </p>
                <a href="#" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".4s"
              style={{
                background: `url("../../assets/img/services/services_item02.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon06.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="#">Siding Contractor</a>
                </h2>
                <h2 className="number">02</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="#">Siding Contractor</a>
                </h2>
                <p>
                Our team of experienced professionals specializes in a variety of siding services, including installation, repair, and replacement.
                </p>
                <a href="#" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                background: `url("../../assets/img/services/services_item03.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon03.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="#">Deck Builder</a>
                </h2>
                <h2 className="number">03</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="#">Deck Builder</a>
                </h2>
                <p>
                Our experienced team specializes in building custom decks tailored to your outdoor living needs.
                </p>
                <a href="#" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                background: `url("../../assets/img/services/services_item04.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon01.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="#">Gutter Installation</a>
                </h2>
                <h2 className="number">04</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="#">Gutter Installation</a>
                </h2>
                <p>
                Our skilled technicians provide comprehensive gutter services, including replacement, repair, and cleaning. 
                </p>
                <a href="#" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                background: `url("../../assets/img/services/services_item05.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon05.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="#">Window Installation</a>
                </h2>
                <h2 className="number">05</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="#">Window Installation</a>
                </h2>
                <p>
                Our expert team offers professional window installation services, including replacement, repair, and energy-efficient upgrades.
                </p>
                <a href="#" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".9s"
              style={{
                background: `url("../../assets/img/services/services_item06.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon06.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Roofing Animation</a>
                </h2>
                <h2 className="number">06</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Roofing Animation</a>
                </h2>
                <p>
                  There are many variations of passages of Lorem a Ipsum
                  available, but the majority have suffered ali teration in some
                  form
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
