import React from "react";

function FeatureArea() {
  return (
    <>
      <section className="features-area pt-120 pb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="features-item wow fadeInUp" data-wow-delay=".2s">
                <div className="features-item-inner">
                  <div className="features-content">
                    <h4 className="title">Roofing Contractor</h4>
                    <p>
                    Our comprehensive roofing services include everything from power washing and moss removal to roof inspections and repairs. 
                    </p>
                    <a href="/services/services-roofing" className="link-btn">
                      <i className="fas fa-arrow-right"></i> Read More
                    </a>
                  </div>
                  <div className="features-icon">
                    <img
                      src={
                        require(`../../assets/img/icon/features_icon02.svg`)
                          .default
                      }
                      alt=""
                      style={{ fill: 'blue' }} 
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item wow fadeInUp" data-wow-delay=".4s">
                <div className="features-item-inner">
                  <div className="features-content">
                    <h4 className="title">Siding Contractor</h4>
                    <p>
                    Our team of experienced professionals specializes in a variety of siding services, including installation, repair, and replacement.
                    </p>
                    <a href="/services/services-siding" className="link-btn">
                      <i className="fas fa-arrow-right"></i> Read More
                    </a>
                  </div>
                  <div className="features-icon">
                    <img
                      src={
                        require(`../../assets/img/icon/features_icon02.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item wow fadeInUp" data-wow-delay=".6s">
                <div className="features-item-inner">
                  <div className="features-content">
                    <h4 className="title">Gutter Installation</h4>
                    <p>
                    Our skilled technicians provide comprehensive gutter services, including replacement, repair, and cleaning. 
                    </p>
                    <a href="/services/services-gutter" className="link-btn">
                      <i className="fas fa-arrow-right"></i> Read More
                    </a>
                  </div>
                  <div className="features-icon">
                    <img
                      src={
                        require(`../../assets/img/icon/features_icon03.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureArea;
